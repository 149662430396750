import React from 'react'
// import { Script } from 'gatsby'
import * as styles from './ParmonicEmbed.module.scss'

// renders a Parmonic embed div and loads the Parmonic scripts via Gatsby Script component
// the embed div is passed as a prop from Sanity
// Example div: <div data-v-id="moment/104317" class="parmonic-widget" data-layout="single"></div>

const ParmonicEmbed = (props) => {
  const { embed } = props

  return (
    <div className={styles.container}>
      <div dangerouslySetInnerHTML={{ __html: embed }}></div>
    </div>
  )
}

export default ParmonicEmbed
