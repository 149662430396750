import React from 'react'

function TwitterIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="19" fill="none" viewBox="0 0 23 19">
      <path
        fill="#fff"
        d="M7.177 18.185c8.608 0 13.317-6.898 13.317-12.877 0-.194-.005-.392-.014-.586a9.338 9.338 0 002.335-2.343 9.476 9.476 0 01-2.687.71A4.581 4.581 0 0022.186.587a9.605 9.605 0 01-2.97 1.099A4.738 4.738 0 0016.558.318a4.826 4.826 0 00-2.967.477 4.598 4.598 0 00-2.057 2.122 4.395 4.395 0 00-.3 2.895c-1.86-.09-3.68-.558-5.341-1.372A13.222 13.222 0 011.59 1.086a4.402 4.402 0 00-.511 3.297 4.534 4.534 0 001.96 2.743 4.81 4.81 0 01-2.121-.564v.056c0 1.045.373 2.059 1.058 2.868a4.71 4.71 0 002.697 1.57 4.806 4.806 0 01-2.111.078c.299.899.88 1.685 1.664 2.249.784.564 1.73.877 2.706.896a9.594 9.594 0 01-5.814 1.94c-.373-.001-.747-.024-1.118-.067a13.61 13.61 0 007.177 2.033z"
      ></path>
    </svg>
  )
}

export default TwitterIcon
