// extracted by mini-css-extract-plugin
export var articleBlock = "ArticleMain-module--articleBlock--8dcea";
export var articleContent = "ArticleMain-module--articleContent--cbfa4";
export var articleFooter = "ArticleMain-module--articleFooter--86c3d";
export var articleLayout = "ArticleMain-module--articleLayout--9f719";
export var articleLeft = "ArticleMain-module--articleLeft--f2ad8";
export var articleMain = "ArticleMain-module--articleMain--7fa0b";
export var articleRight = "ArticleMain-module--articleRight--6d537";
export var authorContainer = "ArticleMain-module--authorContainer--d863a";
export var consistentContainer = "ArticleMain-module--consistentContainer--b31f1";
export var contentWrap = "ArticleMain-module--contentWrap--6da9a";
export var copyButton = "ArticleMain-module--copyButton--dc711";
export var imageWrap = "ArticleMain-module--imageWrap--6b5be";
export var leftWrap = "ArticleMain-module--leftWrap--9ede1";
export var socialIcon = "ArticleMain-module--socialIcon--bc6ce";