import React from 'react'
import {graphql} from 'gatsby'
import ArticleMain from '../components/ArticleMain/ArticleMain';
import FilterBreak from '../components/FilterBreak/FilterBreak';
import Layout from '../components/Layout/Layout'
import NewsFilter from '../components/NewsFilter/NewsFilter'
import * as styles from '../styles/News.module.scss'
import Seo from '../components/Seo/Seo';

export const query = graphql`
  query article($id: String!) {
    article: sanityArticle(id: {eq: $id}) {
        id
        title
        slug {
          current
        }
        categories {
          id
          title
        }
        topics {
          id
          title
        }
        date
        excerpt
        image {
          asset {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1600)
          }
        }
        video
        singlePhoto{
          asset {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1600)
          }
        }
        _rawSingleContent(resolveReferences: {maxDepth: 2})
        singleContent{
            children {
                  text
                }
                style
            }
        _rawAuthor
      }
    footer: sanityArticleFooter {
      _rawFooterText
    }
    featured: sanityNewsSettings {
      browserTitle
      seoData {
        title
        description
        image {
          asset {
            url
          }
        }
      }
      featuredArticles {
        id
        title
      }
    }
    sidebar: sanityNewsSettings {
        sidebarArticles {
          id
          title
        }
      }
      topics: allSanityTopic {
        nodes {
          title
          slug {
            current
          }
        }
      }
      categories: allSanityCategory {
        nodes {
          title
          slug {
            current
          }
        }
      }
    }
  `

const ArticleTemplate = props => {
  const { data, location } = props
  // console.log(data);
  return (
    <Layout>
      <div className={styles.newsWrapper} id="news-wrapper">
        {/* <h1 id="news-header" className={styles.newsHeader}>
          <span><img src={pincailogo} alt="Pinc AI Logo" /></span>
          <span>Newsfeed</span>
        </h1> */}
        <FilterBreak height={120} />
        <NewsFilter data={data} />

        {/* using gatsby location prop to get the current url */}

        <ArticleMain data={data} location={location}/>

      </div>
    </Layout>
  )
}

export default ArticleTemplate

export const Head = (props) => {
  const { data } = props;
  return (
    <Seo title={data.featured.browserTitle} seo={data.featured.seoData} />
  )
}