import React from 'react'

function LinkedInIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" fill="none" viewBox="0 0 23 22">
      <g clipPath="url(#clip0_3745_1974)">
        <path
          fill="#fff"
          d="M20.779 0H2.032C1.134 0 .407.709.407 1.586V20.41c0 .877.727 1.59 1.625 1.59h18.747c.898 0 1.628-.713 1.628-1.585V1.585C22.407.71 21.677 0 20.78 0zM6.934 18.747H3.67V8.246h3.265v10.501zM5.301 6.815a1.892 1.892 0 11-.006-3.783A1.892 1.892 0 015.3 6.815zm13.854 11.932h-3.262v-5.104c0-1.216-.021-2.785-1.697-2.785-1.697 0-1.955 1.328-1.955 2.699v5.19H8.984V8.246h3.128V9.68h.043c.434-.825 1.5-1.697 3.085-1.697 3.304 0 3.915 2.174 3.915 5.001v5.762z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3745_1974">
          <path fill="#fff" d="M0 0H22V22H0z" transform="translate(.407)"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default LinkedInIcon
