import React, { useState, useEffect } from 'react'
import '../../styles/global.scss'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { PortableText } from '@portabletext/react'
import { RiShareBoxLine } from 'react-icons/ri'
import SideBar from '../SideBar/SideBar'
import VideoEmbed from '../VideoEmbed/VideoEmbed'
import formatDate from '../../utils/formatDate'
import TwitterIcon from '../svg/TwitterIcon'
import LinkedInIcon from '../svg/LinkedInIcon'
import PincHelmet from '../Helmet/PincHelmet'
import ParmonicEmbed from '../ParmonicEmbed/ParmonicEmbed'
import { Script } from 'gatsby'
import * as styles from './ArticleMain.module.scss'


export default function ArticleMain({ data, location }) {
  var cats = []
  var date = data.article.date
  var media = []
  // console.log(data);
  // console.log(data.footer);
  const [currentUrl, setCurrentUrl] = useState('');
  const [scriptIsLoaded, setScriptIsLoaded] = useState();
  const customComponents = {
    types: {
        image: ({ value }) => <img src={value.asset.url} alt={value.asset.filename} />,
        parmonicEmbed: ({ value }) => <ParmonicEmbed embed={value.embedDiv} />,
    }
}

  useEffect(() => {

    // using location from props provided by gatsby
    setCurrentUrl(location.href)

  }, [location])

  if (data.article.video !== null) {
    var url = data.article.video
    var match = /vimeo.*\/(\d+)/i.exec(url)
    var id = match[1]
    var src = 'https://player.vimeo.com/video/' + id + '?h=95e774c3ef'
    media.push(
      <div className={styles.imageWrap} key={data.article.id}>
        <VideoEmbed videoSrcURL={src} videoTitle={data.article.title} />
      </div>
    )
  } else {
    media.push(
      <div className={styles.imageWrap} key={data.article.id}>
        {data.article.singlePhoto && (
          <div>
            <GatsbyImage
              alt={data.topHeader || 'News Photo'}
              image={data.article.singlePhoto.asset.gatsbyImageData}
            ></GatsbyImage>
          </div>
        )}
      </div>
    )
  }
  // console.log(data.article._rawSingleContent);
  return (
    <>
      <PincHelmet data={data.article}>
        {/* twitter card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={data.article.title | 'PINC AI'} />
        {data.article?.singlePhoto && (
          <meta name="twitter:image" content={data.article.singlePhoto.asset.gatsbyImageData.images.fallback.src} />
        )}
        {/* open graph tags */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={data.article.title} />
        {data.article?.singlePhoto && (
          <meta
            property="og:image"
            content={data.article.singlePhoto.asset.gatsbyImageData.images.fallback.src}
          />
        )}
      </PincHelmet>
      <div className={styles.articleMain}>
        <div className={styles.articleLayout} id="article-layout">
          <div className={styles.articleLeft}>
            <div className={styles.leftWrap} id="article-main">
              <div className={styles.articleBlock} id="block-head">
                <Link to="/news">
                  <AiOutlineArrowLeft /> NEWSFEED HOME
                </Link>
                <div>
                  <h4>Category:</h4>
                  {data.article.categories.forEach((cat, index) => {
                    cats.push(<span key={index}>{cat.title}</span>)
                  })}
                  {cats}
                </div>
              </div>

              {media}

              <div className={styles.contentWrap}>
                {date && <h4>{formatDate(date)}</h4>}
                <h1>{data.article.title}</h1>
                {data.article._rawSingleContent && (
                  <div className={styles.articleContent}>
                    <PortableText value={data.article._rawSingleContent} components={customComponents} />
                  </div>
                )}
                {data.article._rawAuthor && (
                  <div className={styles.authorContainer}>
                    <PortableText value={data.article._rawAuthor} />
                  </div>
                )}
                <div className={styles.articleFooter}>
                  <span
                    onClick={copyClick}
                    role="textbox"
                    tabIndex={0}
                    onKeyDown={copyClick}
                    className={styles.copyButton}
                    id="copy-btn"
                    aria-label="Copy link to clipboard"
                  >
                    Share <RiShareBoxLine />
                  </span>
                  <a
                    className={styles.socialIcon}
                    href={`https://twitter.com/intent/tweet?url=${currentUrl}&text=${data.article.title}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Share on Twitter"
                  >
                    <TwitterIcon />
                  </a>
                  <a
                    className={styles.socialIcon}
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}&title=${data.article.title}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Share on LinkedIn"
                  >
                    <LinkedInIcon />
                  </a>
                </div>
              </div>
            </div>
            {data.footer?._rawFooterText && (
              <div className={styles.consistentContainer}>
                <PortableText value={data.footer._rawFooterText} />
              </div>
            )}
          </div>

          <div className={styles.articleRight}>
            <SideBar data={data} />
          </div>
        </div>
      </div>
      {/* loading parmonic scripts here - intermittent when loaded inside ParmonicEmbed component that is passed to PortableText */}
      <Script src="https://js.static.parmonic.ai/v5/aw-single.js" onLoad={() => setScriptIsLoaded(true) }/>
      {scriptIsLoaded && <Script src="https://js.static.parmonic.ai/v5/aw-init.js" />}
    </>
  )
}

const isBrowser = typeof window !== 'undefined'
function copyClick(e) {
  if (isBrowser) {
    var copyBtn = document.getElementById('copy-btn')
    var oldCopy = copyBtn.innerHTML
    if (isBrowser) {
      var innerCopy = window.location.href
    }

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(innerCopy)
    copyBtn.innerHTML = 'Copied!'
    setTimeout(function () {
      copyBtn.innerHTML = oldCopy
    }, 200)
  }
}
